import { auth } from "../firebase/firebase-config";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { types } from "../types/types";

export const startGoogleLogin = () => {
  const provider = new GoogleAuthProvider();
  return (dispatch) => {
    signInWithPopup(auth, provider)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName, user.photoURL));
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const login = (uid, displayName, imagenUrl) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
    imagenUrl,
  },
});

export const startLogout = () => {
  return async (dispatch) => {
    await auth.signOut();
    dispatch(logout());
  };
};

export const logout = () => {
  return {
    type: types.logout,
  };
};
