import React from "react";

import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";

import { Navbar } from "./components/ui/Navbar";
import { Footer } from "./components/ui/Footer";
import { Dashboard } from "./pages/Dashboard";
import { PostJob } from "./pages/PostJob";
import { MyCampaigns } from "./pages/MyCampaigns";

export const App = () => {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/post" element={<PostJob />} />
            <Route exact path="/campaigns" element={<MyCampaigns />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </Router>
      </Provider>
    </>
  );
};
