import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { doc, deleteDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import testCampaign from "../img/testCampaign.png";
import Cookies from "universal-cookie";

export const MyCampaigns = () => {
  let cookies = new Cookies();
  let cookieUid = cookies.get("uid");

  const [jobsCampaign, setJobsCampaign] = useState();

  const loadJobsCampaigns = async () => {
    const jobsRef = collection(db, "Jobs");
    let allJobsCampaign = {};
    if (cookieUid) {
      const q = query(jobsRef, where("createdBy", "==", cookieUid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        allJobsCampaign[doc.id] = doc.data();
      });
      return allJobsCampaign;
    }
  };

  const handleDeleteJob = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminalo!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const jobsRef = collection(db, "Jobs");
        const jobRef = doc(jobsRef, id);
        await deleteDoc(jobRef);
        Swal.fire(
          "Eliminado!",
          `Su campaña de ${jobsCampaign.position}, ha sido eliminada.`,
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  };

  useEffect(() => {
    loadJobsCampaigns().then((jobs) => {
      setJobsCampaign(jobs);
    });
  }, []);

  return (
    <section style={{ backgroundColor: "#221f2b" }}>
      <div className="w-full banner-h container-xl">
        <div className="w-container">
          {/* <h1 className="ml-20 text-5xl text-white">Campañas activas</h1> */}
          <div
            className="w-full p-8 rounded-md"
            style={{ backgroundColor: "#221f2b" }}
          >
            <div className="flex items-center justify-center pb-6 ">
              {cookieUid ? (
                <div>
                  <h1 className="text-5xl font-bold text-white">Campañas </h1>
                </div>
              ) : (
                <div>
                  <h1 className="text-5xl font-bold text-white">
                    Preview campañas
                  </h1>
                  <h3 className="mt-5 text-xl font-bold text-white">
                    {" "}
                    Tienes que iniciar sesión para ver tus campañas
                  </h3>
                </div>
              )}
            </div>
            <div>
              <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 text-2xl font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                          Puesto de trabajo
                        </th>
                        <th className="px-5 py-3 text-2xl font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                          Empresa
                        </th>
                        <th className="px-5 py-3 text-2xl font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                          Fecha de publicación
                        </th>

                        <th className="px-5 py-3 text-2xl font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                          Eliminar campaña
                        </th>
                        <th className="px-5 py-3 text-2xl font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobsCampaign ? (
                        Object.entries(jobsCampaign).map(([id, job]) => (
                          <tr key={id}>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img
                                    className="w-full h-full rounded-full"
                                    src={job.logo_company}
                                    alt="logo_company"
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {job.position}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {job.name_company}
                              </p>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {new Date(job.date).toLocaleDateString()}
                              </p>
                            </td>

                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                {/* <span
                                aria-hidden
                                className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                              ></span>
                              <span className="relative">Activo</span> */}
                                <button
                                  className="px-10 py-2 text-xl font-bold text-white bg-red-500 rounded-full hover:bg-red-700"
                                  onClick={() => {
                                    handleDeleteJob(id);
                                  }}
                                >
                                  Eliminar
                                </button>
                              </span>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              {/* <button className="px-4 py-2 text-xl font-bold text-white rounded h-14 bg-button focus:outline-none focus:shadow-outline">
                            Republicar
                          </button> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img
                                    className="w-full h-full rounded-full"
                                    src={testCampaign}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    Ej: Desarrollador Frontend
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                Test Company
                              </p>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                02/02/2022
                              </p>
                            </td>

                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                {/* <span
                                aria-hidden
                                className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                              ></span>
                              <span className="relative">Activo</span> */}
                                <button className="px-10 py-2 text-xl font-bold text-white bg-red-500 rounded-full hover:bg-red-700">
                                  Eliminar
                                </button>
                              </span>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              {/* <button className="px-4 py-2 text-xl font-bold text-white rounded h-14 bg-button focus:outline-none focus:shadow-outline">
                            Republicar
                          </button> */}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img
                                    className="w-full h-full rounded-full"
                                    src={testCampaign}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    Ej: Desarrollador Backend
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                Test Company
                              </p>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                27/01/2022
                              </p>
                            </td>

                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                {/* <span
                               aria-hidden
                               className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                             ></span>
                             <span className="relative">Activo</span> */}
                                <button className="px-10 py-2 text-xl font-bold text-white bg-red-500 rounded-full hover:bg-red-700">
                                  Eliminar
                                </button>
                              </span>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              {/* <button className="px-4 py-2 text-xl font-bold text-white rounded h-14 bg-button focus:outline-none focus:shadow-outline">
                           Republicar
                         </button> */}
                            </td>
                          </tr>

                          <tr>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img
                                    className="w-full h-full rounded-full"
                                    src={testCampaign}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    Ej: DevOps Services
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                Test Company
                              </p>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                19/01/2022
                              </p>
                            </td>

                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                {/* <span
                               aria-hidden
                               className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                             ></span>
                             <span className="relative">Activo</span> */}
                                <button className="px-10 py-2 text-xl font-bold text-white bg-red-500 rounded-full hover:bg-red-700">
                                  Eliminar
                                </button>
                              </span>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              {/* <button className="px-4 py-2 text-xl font-bold text-white rounded h-14 bg-button focus:outline-none focus:shadow-outline">
                           Republicar
                         </button> */}
                            </td>
                          </tr>

                          <tr>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img
                                    className="w-full h-full rounded-full"
                                    src={testCampaign}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    Ej: Arquitecto de software
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                Test Company
                              </p>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                14/01/2022
                              </p>
                            </td>

                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                {/* <span
                               aria-hidden
                               className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                             ></span>
                             <span className="relative">Activo</span> */}
                                <button className="px-10 py-2 text-xl font-bold text-white bg-red-500 rounded-full hover:bg-red-700">
                                  Eliminar
                                </button>
                              </span>
                            </td>
                            <td className="px-5 py-5 text-xl bg-white border-b border-gray-200">
                              {/* <button className="px-4 py-2 text-xl font-bold text-white rounded h-14 bg-button focus:outline-none focus:shadow-outline">
                           Republicar
                         </button> */}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="flex flex-col items-center px-5 py-5 bg-gray-100 border-t border-gray-200 2xl:flex-row xs:justify-between"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
