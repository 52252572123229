import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: #2a2243;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 85%;
  bottom: 40px;
  height: 60px;
  font-size: 4rem;
  z-index: 1;
  cursor: pointer;
  color: #2a2243;
  @media (min-width: 768px) {
    left: 95%;
    height: 80px;
  }
`;
