import React, { useState } from "react";
import { db } from "../firebase/firebase-config";
import { collection, addDoc } from "../firebase/firebase-config";
import { Card } from "react-bootstrap";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import "../components/css/postJob.css";

export const PostJob = () => {
  let dateObj = new Date().toISOString();

  let cookies = new Cookies();

  const [colorCheckbox, setColorCheckbox] = useState(false);
  const [urlActive, setUrlActive] = useState(true);
  const [emailActive, setEmailActive] = useState(false);
  const [uploadImg, setUploadImg] = useState("");
  const [dataForm, setDataForm] = useState({
    logo_company: "",
    name_company: "",
    position: "",
    contract: "",
    labels: [],
    date: dateObj,
    rango_salarial_min: "",
    rango_salarial_max: "",
    location: "",
    colorBackground: "#f1ebfa",
    colorText: "#000000",
    url: "",
    email: "",
    workModel: "",
    price: "29,99",
    createdBy: cookies.get("uid"),
  });

  let {
    name_company,
    position,
    contract,
    labels,
    date,
    rango_salarial_min,
    rango_salarial_max,
    location,
    colorBackground,
    colorText,
    url,
    email,
    workModel,
    price,
  } = dataForm;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cookies.get("user-token")) {
      if (isFormValid()) {
        setDataForm({ logo_company: uploadImgCloud() });
        try {
          const docRef = await addDoc(collection(db, `Jobs`), dataForm);
          Swal.fire({
            icon: "success",
            title: "¡Publicación exitosa!",
            showConfirmButton: false,
            timer: 1500,
          });
          resetForm();
          console.log("Document written with ID: ", docRef.id);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          return true;
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Tiene que iniciar sesión para poder publicar un trabajo",
      });
    }
  };
  const isFormValid = () => {
    if (name_company.trim().length <= 2) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El nombre de la empresa es muy corto",
      });
      return false;
    } else if (position.trim().length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El campo de posición es obligatorio",
      });
      return false;
    } else if (labels.length < 2) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Al menos 3 etiquetas son obligatorias",
      });
      return false;
    } else if (location.trim().length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El campo de ubicación es obligatorio",
      });
      return false;
    } else if (workModel.trim().length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El campo de modelo de trabajo es obligatorio",
      });
      return false;
    }
    return true;
  };

  const resetForm = () => {
    document.getElementById("reset").click();
    setDataForm({
      rango_salarial_min: "",
      rango_salarial_max: "",
      uploadImg: "",
    });
    document.getElementById("logo-company").value = "";
  };

  const uploadImgCloud = async (file) => {
    const cloudUrl = "https://api.cloudinary.com/v1_1/cristiandgr/upload";

    const formData = new FormData();
    formData.append("upload_preset", "remotly");
    formData.append("file", file);

    try {
      const resp = await fetch(cloudUrl, {
        method: "POST",
        body: formData,
      });

      if (resp.ok) {
        const cloudResponse = await resp.json();
        return cloudResponse.secure_url;
      } else {
        throw await resp.json();
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <section style={{ backgroundColor: "#221f2b" }}>
      <div className="w-full container-xl">
        <div className="flex flex-wrap justify-center md:flex-row-reverse md:justify-around md:items-center ">
          <div className="w-full max-w-3xl md:mr-36">
            <form
              className="px-8 pt-6 pb-8 mt-10 mb-20 bg-white rounded shadow-md"
              onSubmit={handleSubmit}
            >
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700 caca">
                  Puesto de trabajo*
                </label>
                <input
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  onChange={(e) =>
                    setDataForm({ ...dataForm, position: e.target.value })
                  }
                  type="text"
                  placeholder="Ej: Desarrollador Frontend"
                />
                <span className="block mt-3 text-base font-bold text-neutral-400">
                  Especifique una posición de trabajo única como "Full Stack
                  Engineer" o "React Developer", no una frase como "Buscando
                  Product Manager / Full Dev / DevOps"
                </span>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Nombre de la empresa*
                </label>
                <input
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="name"
                  onChange={(e) =>
                    setDataForm({ ...dataForm, name_company: e.target.value })
                  }
                  type="text"
                  placeholder="Ej: Amazon, Clevertech, Toptal"
                />
                <span className="block mt-3 text-base font-bold text-neutral-400">
                  La marca/nombre comercial de su empresa sin Inc., Ltd., B.V.,
                  Pte., etc.
                </span>
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Tipo de contrato*
                </label>
                <select
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none cursor-pointer focus:outline-none focus:shadow-outline"
                  name="jornada"
                  id="jornada"
                  onChange={(e) =>
                    setDataForm({ ...dataForm, contract: e.target.value })
                  }
                >
                  <option value="">Seleccione un opción</option>
                  <option value="Tiempo completo">Tiempo completo</option>
                  <option value="Tiempo parcial">Tiempo parcial</option>
                  <option value="Por contrato">Por contrato</option>
                  <option value="Temporal">Temporal</option>
                  <option value="Prácticas">Prácticas</option>
                  <option value="Voluntario">Voluntario</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Tags*
                </label>
                <input
                  onChange={(e) =>
                    setDataForm({
                      ...dataForm,
                      labels: e.target.value.split(","),
                    })
                  }
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none tags focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Node.js, React, Redux, javascript, DevOps, etc."
                />
                <span className="block mt-3 text-base font-bold text-neutral-400">
                  Se prefieren las etiquetas cortas. Utiliza etiquetas como
                  industria y pila tecnológica, y separa las etiquetas múltiples
                  con una coma.
                </span>
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Logo de la empresa
                </label>
                <div
                  className="text-xl font-bold text-gray-700 logo-box hover:opacity-75"
                  style={{ backgroundImage: `url("${uploadImg}")` }}
                >
                  {uploadImg === "" ? (
                    <p className="logo-text">📷 Cargar imagen</p>
                  ) : null}
                  <input
                    id="logo-company"
                    onChange={(e) => {
                      uploadImgCloud(e.target.files[0]).then((url) => {
                        setDataForm({ ...dataForm, logo_company: url });
                      });
                      setUploadImg(URL.createObjectURL(e.target.files[0]));
                    }}
                    type="file"
                    name="company_logo"
                    className="input_company_logo"
                    accept=".jpg,.png"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Rango salarial
                </label>
                <div className="flex justify-between ">
                  <input
                    className="w-2/5 h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    onChange={(e) => {
                      setDataForm({
                        ...dataForm,
                        rango_salarial_min: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="Mínimo anual"
                  />
                  <input
                    className="w-2/5 h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        rango_salarial_max: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="Máximo anual"
                  />
                </div>
                <span className="block mt-3 text-base font-bold text-neutral-400">
                  Especificar un rango salarial es beneficioso para captar el
                  interés de los aplicantes. <br />
                  Si se trata de un proyecto de 2 semanas por 1.000 euros, el
                  equivalente anual sería de 1.000 euros / 2 semanas * 52
                  semanas = 26.000 euros.
                </span>
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Modelo de trabajo*
                </label>
                <input
                  onChange={(e) =>
                    setDataForm({ ...dataForm, workModel: e.target.value })
                  }
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Remoto - Híbrido - Presencial"
                />
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Ubicación*
                </label>
                <input
                  onChange={(e) =>
                    setDataForm({ ...dataForm, location: e.target.value })
                  }
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Worldwide - Madrid, España - Barcelona etc."
                />
              </div>

              <div
                className={`flex justify-between ${
                  colorCheckbox ? "active-opacity" : "inactive-opacity"
                }`}
              >
                <input
                  type="checkbox"
                  id="checker"
                  onChange={(e) => {
                    setColorCheckbox(e.target.checked);
                    setDataForm({
                      ...dataForm,
                      price: e.target.checked ? "34,99" : "29,99",
                      colorBackground: "#ffffff",
                      colorText: "#000000",
                    });
                  }}
                  className="w-8 h-8 cursor-pointer form-checkbox p accent-indigo-700"
                />
                <div className="flex flex-col items-center mb-6">
                  <label className="block mb-2 text-3xl font-bold text-gray-700">
                    Color de fondo
                  </label>
                  <input
                    disabled={!colorCheckbox}
                    value={colorBackground}
                    className="h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none w-1/8 focus:outline-none focus:shadow-outline"
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        colorBackground: e.target.value,
                      })
                    }
                    type="color"
                  />
                </div>
                <div className="flex flex-col items-center mb-6">
                  <label className="block mb-2 text-3xl font-bold text-gray-700">
                    Color de texto
                  </label>
                  <input
                    disabled={!colorCheckbox}
                    value={colorText}
                    className="h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none w-1/8 focus:outline-none focus:shadow-outline"
                    onChange={(e) =>
                      setDataForm({ ...dataForm, colorText: e.target.value })
                    }
                    type="color"
                  />
                </div>
              </div>
              <span className="block mt-3 mb-5 text-base font-bold text-neutral-400">
                🎨 Destaca con el color de la marca de tu empresa.
              </span>
              <div
                className={`mb-6 ${
                  urlActive ? "active-opacity" : "inactive-opacity"
                }`}
              >
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  URL
                </label>

                <input
                  {...(urlActive ? { required: true } : {})}
                  onChange={(e) =>
                    setDataForm({ ...dataForm, url: e.target.value })
                  }
                  onFocus={() => {
                    setUrlActive(true);
                    setEmailActive(false);
                    setDataForm({ ...dataForm, email: "" });
                  }}
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="https://example.com"
                  value={url}
                />
              </div>
              <span className="flex justify-center text-xl font-bold leading-tight text-gray-700 ">
                O en su defecto
              </span>
              <div
                className={`mb-6 ${
                  emailActive ? "active-opacity" : "inactive-opacity"
                }`}
              >
                <label className="block mb-2 text-3xl font-bold text-gray-700">
                  Email
                </label>
                <input
                  {...(emailActive ? { required: true } : {})}
                  onChange={(e) =>
                    setDataForm({ ...dataForm, email: e.target.value })
                  }
                  onFocus={() => {
                    setUrlActive(false);
                    setEmailActive(true);
                    setDataForm({ ...dataForm, url: "" });
                  }}
                  className="w-full h-16 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="example@gmail.com"
                  value={email}
                />
              </div>
              <div className="flex items-center justify-center w-full mt-8">
                <button
                  className="w-1/2 h-20 px-4 py-2 text-2xl font-bold text-white rounded bg-button focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Publicar trabajo
                  {/* Publicar trabajo ~ {price} € */}
                </button>
              </div>
              <input type="hidden" id="reset" type="reset" />
            </form>
          </div>
          <div className="md:ml-32">
            <a
              className="card-opener"
              href={
                urlActive
                  ? url
                  : "mailto:" +
                    email +
                    "?subject=" +
                    "Nuevo%20candidato%20de%20Remotely%20para%20" +
                    position +
                    "%20en%20" +
                    name_company +
                    "&body=Hola,%20Estoy%20interesado%20en%20el%20puesto%20de%20" +
                    position +
                    "%20publicado%20en%20Remotely.%20Le%20adjunto%20mi%20CV%20y%20mis%20datos%20personales.%20Gracias!"
              }
              target="_blank"
              rel="noreferrer"
            >
              <Card
                className="desktopWidth"
                style={{
                  backgroundColor: colorBackground,
                }}
              >
                <Card.Body
                  style={{
                    color: colorText,
                  }}
                >
                  <Card.Title>
                    {uploadImg === "" ? (
                      <Card.Img
                        style={{ display: "none" }}
                        className="logo_company"
                        src={uploadImg}
                        alt="Logo de la empresa"
                      />
                    ) : (
                      <Card.Img
                        className="logo_company"
                        src={uploadImg}
                        alt="Logo de la empresa"
                      />
                    )}
                  </Card.Title>

                  <Card.Title>{position}</Card.Title>
                  <Card.Subtitle>{name_company}</Card.Subtitle>
                  <div className="flex justify-center">
                    <Card.Text>
                      <span className="mr-8">{contract}</span>
                    </Card.Text>
                    <Card.Text>
                      <span className="mr-8">{workModel}</span>
                    </Card.Text>
                    <Card.Text>
                      <span>{location}</span>
                    </Card.Text>
                  </div>
                  {rango_salarial_min || rango_salarial_max !== "" ? (
                    <Card.Text>
                      {`${rango_salarial_min}€ - ${rango_salarial_max}€`}
                    </Card.Text>
                  ) : null}
                  <Card.Text>
                    {name_company !== ""
                      ? new Date(date).toLocaleDateString()
                      : null}
                  </Card.Text>

                  <div className="labels-container">
                    {labels !== undefined
                      ? labels.map((label) => (
                          <Card.Text
                            className="card-labels"
                            key={label}
                            style={{
                              borderColor: colorText,
                              color: colorText,
                            }}
                          >
                            {label}
                          </Card.Text>
                        ))
                      : null}
                  </div>
                </Card.Body>
              </Card>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
