import React from "react";

export const Footer = () => {
  return (
    <section className="flex justify-center bg-mycolor">
      <footer className="container">
        <div className="flex justify-center h-20">
          <ul className="flex mt-5 text-xl text-white">
            <li>2022 © Remotely All rights reserved.</li>
          </ul>
        </div>
      </footer>
    </section>
  );
};
