import React from "react";
import bannerVideo from "../img/banner-video.mp4";
import "./css/Banner.css";

export const Banner = () => {
  return (
    <section className="bg-mycolor banner-h">
      <div className="container min-w-full">
        <div>
          <h1 className="pt-32 text-5xl font-bold text-center text-white my_animation littleScreen-first">
            Encuentra tu trabajo de forma rápida y sencilla
          </h1>
          <div className="flex md:justify-center video-animation">
            <video
              className="mt-24 littleScreen-second md:mt-20"
              src={bannerVideo}
              width="450"
              height="370"
              autoPlay
              playsInline
              loop
              muted
              controls={false}
            />
          </div>
          <div className="mb-10 mt-24 bottom-animation littleScreen-third md:mt-8">
            <a href="#jobs">
              <p className="mt-20 text-2xl text-center text-white md:mt-16">
                ¿A qué esperas?
              </p>
              <div className="w-10 h-10 p-2 m-auto mt-10 bg-gray-800 rounded-full shadow-lg cursor-pointer animate-bounce ring-1">
                <svg
                  className="w-6 h-6 text-violet-500"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
