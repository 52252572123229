import { useState, useEffect } from "react";
import { Banner } from "../components/Banner";
import { GoTopBtn } from "../components/GoTopBtn";
import { SearchBar } from "../components/SearchBar";
import { Cards } from "../components/Cards";
import { db } from "../firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

export const Dashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState("");

  const loadJobs = async () => {
    const querySnapshot = await getDocs(collection(db, "Jobs"));
    let allJobs = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allJobs.push(doc.data());
    });

    return allJobs;
  };

  useEffect(() => {
    loadJobs().then((jobs) => {
      setJobs(jobs);
    });
  }, []);

  return (
    <>
      <Banner />
      <section className="jobs-section">
        <SearchBar setFilters={setFilters} />
        <div className="cards-container">
          {jobs
            .filter((job) =>
              job.labels !== undefined
                ? job.labels.some((tag) =>
                    tag.toLowerCase().trim().startsWith(filters)
                  )
                : false
            )
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((job) => (
              <Cards key={uuidv4()} data={job} />
            ))}
        </div>
      </section>
      <GoTopBtn />
    </>
  );
};
