import React from "react";
import { Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import "./css/Cards.css";

export const Cards = ({ data }) => {
  return (
    <Card
      className="card-hov desktopWidth"
      style={{ backgroundColor: data.colorBackground }}
    >
      <a
        className="card-opener"
        href={
          data.url !== ""
            ? data.url.includes("?")
              ? data.url + "&referal=remotely"
              : data.url + "?referal=remotely"
            : "mailto:" +
              data.email +
              "?subject=" +
              "Nuevo%20candidato%20de%20remotely%20para%20" +
              data.position +
              "%20en%20" +
              data.name_company +
              "&body=Hola,%20Estoy%20interesado%20en%20el%20puesto%20de%20" +
              data.position +
              "%20publicado%20en%20remotely.%20Le%20adjunto%20mi%20CV%20y%20mis%20datos%20personales.%20Gracias!"
        }
        target="_blank"
        rel="noreferrer"
      >
        <Card.Body
          style={{
            color: data.colorText,
          }}
        >
          <Card.Title>
            {data.logo_company === "" ? (
              <Card.Img
                style={{ display: "none" }}
                className="logo_company"
                src={data.logo_company}
                alt="Logo de la empresa"
              />
            ) : (
              <Card.Img
                className="logo_company"
                src={data.logo_company}
                alt="Logo de la empresa"
              />
            )}
          </Card.Title>

          <Card.Title>{data.position}</Card.Title>
          <Card.Subtitle>{data.name_company}</Card.Subtitle>
          <div className="flex justify-center">
            <Card.Text>
              <span className="mr-8">{data.contract}</span>
            </Card.Text>
            <Card.Text>
              <span className="mr-8">{data.workModel}</span>
            </Card.Text>
            <Card.Text>
              <span>{data.location}</span>
            </Card.Text>
          </div>
          {data.rango_salarial_min || data.rango_salarial_max !== "" ? (
            <Card.Text>
              {`${data.rango_salarial_min}€ - ${data.rango_salarial_max}€`}
            </Card.Text>
          ) : null}
          <Card.Text>{new Date(data.date).toLocaleDateString()}</Card.Text>

          <div className="labels-container">
            {data.labels !== ""
              ? data.labels.map((label) => (
                  <Card.Text
                    className="card-labels"
                    key={uuidv4()}
                    style={{
                      borderColor: data.colorText,
                      color: data.colorText,
                    }}
                  >
                    {label}
                  </Card.Text>
                ))
              : null}
          </div>
        </Card.Body>
      </a>
    </Card>
  );
};
