import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { startGoogleLogin, startLogout } from "../../actions/auth";
import { login } from "../../actions/auth";
import { auth, onAuthStateChanged } from "../../firebase/firebase-config";
import { FaSignOutAlt } from "react-icons/fa";
import Cookies from "universal-cookie";
import logo from "../../img/new-logo.svg";
import googleLogo from "../../img/googleLogo.svg";
import "../css/Navbar.css";

export const Navbar = () => {
  let activeStyle = {
    color: "#5c4a94",
  };
  let cookies = new Cookies();
  const dispatch = useDispatch();

  const handleGoogleLogin = () => {
    dispatch(startGoogleLogin());
  };

  const handleLogout = () => {
    dispatch(startLogout());
    cookies.remove("user-token");
    cookies.remove("uid");
    window.location.reload();
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [dataUser, setDataUser] = useState({
    userName: "",
    imageUrl: "",
  });

  useEffect(() => {
    let cookies = new Cookies();
    onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        dispatch(
          login(user.uid, user.displayName, user.photoURL, user.accessToken)
        );
        setDataUser({
          userName: user.displayName.split(" ")[0],
          imageUrl: user.photoURL,
        });
        cookies.set(
          "user-token",
          { key: user.accessToken },
          { path: "/", expires: new Date(Date.now() + 7200000) }
        );
        cookies.set(
          "uid",
          { key: user.uid },
          { path: "/", expires: new Date(Date.now() + 7200000) }
        );
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, [dispatch, setIsLoggedIn]);

  return (
    <header className="bg-mycolor header-section">
      <div className="container flex container-nav">
        <div className="mt-5 logo md:ml-52 md:-mt-4 sm:-mt-5">
          <a href="/">
            <img className="logoRemotfly" src={logo} alt="logo" />
          </a>
        </div>
        <div className="navbar">
          <nav>
            <ul className="flex mt-12 text-xl text-indigo-50 md:absolute md:left-1/4 md:pl-14 ">
              <li className="pr-3 mr-2 text-xl cursor-pointer isActive hover_color md:mr-14 md:pl-56 md:text-3xl">
                <NavLink
                  to="/"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  TRABAJOS
                </NavLink>
              </li>
              <li className="pr-3 mr-2 text-xl cursor-pointer isActive hover_color md:mr-14 md:text-3xl">
                <NavLink
                  to="/post"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  PUBLICAR
                </NavLink>
              </li>

              <li className="mr-3 text-xl cursor-pointer isActive hover_color md:mr-14 md:text-3xl">
                <NavLink
                  to="/campaigns"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  CAMPAIGN
                </NavLink>
              </li>

              {!isLoggedIn ? (
                <li className="pl-2">
                  <div className="auth__social-network">
                    <div className="google-btn" onClick={handleGoogleLogin}>
                      <div className="google-icon-wrapper">
                        <img
                          className="google-icon"
                          src={googleLogo}
                          alt="google button"
                        />
                      </div>
                      <p className="btn-text">
                        <b>Iniciar sesión</b>
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                <li>
                  <div className="auth__social-network">
                    <div
                      className="google-btn"
                      style={{ backgroundColor: "#221f2b" }}
                      onClick={handleLogout}
                    >
                      <div
                        className="google-icon-wrapper"
                        style={{ backgroundColor: "#221f2b" }}
                      >
                        <img
                          className="google-icon"
                          style={{
                            width: "30px",
                            borderRadius: "50%",
                            height: "30px",
                            top: "-5px",
                            left: "-5px",
                          }}
                          src={dataUser.imageUrl}
                          alt="logo"
                        />
                      </div>
                      <p className="flex items-center btn-text">
                        <b className="mr-9">{dataUser.userName}</b>
                        <FaSignOutAlt />
                      </p>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
