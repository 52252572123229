import React from "react";
import "./css/searchbar.css";
import { useState, useEffect } from "react";

export const SearchBar = ({ setFilters }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    setFilters(text);
  }, [setFilters, text]);

  return (
    <div
      className="flex justify-center w-full"
      style={{ backgroundColor: "#fbfafd" }}
      id="jobs"
    >
      <input
        type="text"
        id="myInput"
        onChange={(event) => setText(event.target.value.toLowerCase())}
        value={text}
        placeholder="Search for tags.."
      ></input>
    </div>
  );
};
