import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getDatabase, ref, child, get, onValue } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCO29nxWLZK9Nw6unDWHa6hh3fx5MYjWrE",
  authDomain: "remotly-e914f.firebaseapp.com",
  projectId: "remotly-e914f",
  storageBucket: "remotly-e914f.appspot.com",
  messagingSenderId: "8892887518",
  appId: "1:8892887518:web:eb94af0e1fa1746cffed4f",
  measurementId: "G-C2BFE49CHC",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore();

export {
  app,
  auth,
  firebase,
  onAuthStateChanged,
  db,
  collection,
  addDoc,
  getDatabase,
  ref,
  child,
  get,
  onValue,
};
